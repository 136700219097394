import React from 'react';
import icon1 from '../images/icon1.png';
import icon2 from '../images/icon2.png';
import icon3 from '../images/icon3.png';

const Choose = () => {
  const Chooses = [
    {
      id: 1,
      img: icon1,
      title: 'Wide Range of Products',
      texts:
        'Choose from a diverse selection of high-quality, trendy products to stock your store. We offer everything from fashion and electronics to home décor and beauty.',
    },
    {
      id: 2,
      img: icon2,
      title: 'Reliable Suppliers',
      texts:
        'Partner with trusted suppliers to ensure your customers receive the best products, delivered on time',
    },
    {
      id: 3,
      img: icon3,
      title: '24/7 Support',
      texts:
        'Our dedicated support team is available around the clock to help you with any questions or issues, ensuring your business runs smoothly.',
    },
  ];
  return (
    <section className='bg-[#0E1523] h-[1190px] md:h-[550px] lg:h-[580px] xl:h-[600px] pt-[44px] md:pt-[50px]'>
      <h3 className='text-white text-center text-[22px] leading-[60px] md:text-[40px] font-normal'>
        Why <span className='text-[#FBE810]'>Choose</span> Us{' '}
      </h3>
      <article className='flex flex-col gap-[56px] md:gap-0 md:flex-row md:max-w-[45rem] md:justify-around px-7 md:px-0 lg:max-w-[60rem] xl:max-w-[75rem] mac:max-w-[83rem] mx-auto pt-[40px] md:pt-[64px]'>
        {Chooses.map((item) => (
          <div className=''>
            <img
              src={item.img}
              alt='choose-icons'
              className='h-[70px] w-[70px] md:h-auto md:w-auto'
            />

            <h3 className='text-white font text-[22px] font-bold leading-normal md:text-[15px] lg:text-[20px] xl:text-[28px] pt-[32px] md:pt-[24px]'>
              {item.title}
            </h3>
            <p className='text-white font text-lg md:text-[12px] lg:text-[16px] xl:text-[20px] md:w-[210px] lg:w-[270px] xl:w-[370px] pt-[16px] md:pt-[29px] leading-[32px] font-medium'>
              {item.texts}
            </p>
          </div>
        ))}
      </article>
    </section>
  );
};

export default Choose;
