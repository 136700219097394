import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import About from './Route/About/About';
import Contact from './Route/Contact/Contact';
import ProductDetails from './Route/ProductDetails/ProductDetails';

const RouterComponent = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/product/:id' element={<ProductDetails />} />
      </Routes>
    </Router>
  );
};

export default RouterComponent;
