import React, { useEffect } from 'react';
import Nav from '../../Nav';
import Footer from '../../Footer';
import Header from './Header';
import Adventure from './Adventure';
import Missions from './Missions';
import Teams from './Teams';
import Experience from '../../Experience';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);
  return (
    <div>
      <Nav />
      <Header />
      <Adventure />
      <Missions />
      <Teams />
      <Experience />
      <Footer />
    </div>
  );
};

export default About;
