import React from 'react';
import hero from '../images/hero.png';
import hero2 from '../images/hero2.png';
import hero3 from '../images/hero3.png';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const Hero = () => {
  // const words = text.split(' ');
  // console.log(words);
  return (
    <section className='bg-[#0E1523]  w-full lg:screen'>
      <figure className='relative z-10 pt-7 md:pt-0'>
        <img
          src={hero2}
          alt='female-wears'
          className='md:h-[420px] md:hidden h-[508px] lg:h-screen w-full object-cover'
        />
        <img
          src={hero3}
          alt='female-wears'
          className='md:h-[420px] hidden md:block h-[508px] lg:h-screen w-full object-cover'
        />
        <figcaption className='absolute z-10 px-8 left-[50%] w-full -translate-x-[50%] bottom-7 md:bottom-2 lg:bottom-7 xl:bottom-20 mac:bottom-7 flex flex-col items-center'>
          <h1 className='text-white font-normal text-[24px] leading-normal lg:leading-[60px] md:text-2xl lg:text-[32px] xl:text-[50px] text-center'>
            Your Destination for Trendy, High
            <br className='md:block hidden' /> Quality Products
          </h1>

          <p className='text-white text-base font  text-center font-medium leading-normal pt-[13px] md:text-[14px] lg:text-[18px] xl:text-[26px]'>
            At JayKay Edwards, we bring you the latest and greatest products
            <br className='md:block hidden' /> from around the globe, all in one
            place.
          </p>
          <button className='bg-[#FBE810] w-full md:w-auto mt-[30px] rounded-lg font-bold md:px-5 md:text-sm lg:text-base lg:px-7 py-3 text-[#010917]'>
            <Link to='/contact'>Get in touch</Link>
          </button>
        </figcaption>
      </figure>
    </section>
  );
};

export default Hero;
