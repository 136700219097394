import React from 'react';
import Frame1 from '../images/Frame1.png';
import Frame2 from '../images/Frame2.png';
import Frame3 from '../images/Frame3.png';
import Frame4 from '../images/Frame4.png';
import f1 from '../images/f1.png';
import f2 from '../images/f2.png';
import f3 from '../images/f3.png';
import f4 from '../images/f4.png';

const Categories = () => {
  return (
    <main className='bg-[#010917] pt-[20px] md:h-[540px] lg:h-[680px] xl:h-[720px] md:pt-[55px] lg:pt-[65px] xl:pt-[80px]'>
      <h4 className='text-white text-center text-[22px] md:text-[40px] font-normal leading-[60px] capitalize'>
        featured <span className='text-[#FBE810]'>categories</span>
      </h4>
      <article className=' flex-col w-full pt-[49px] hidden md:flex md:flex-row justify-center'>
        <figure className='relative'>
          <img src={Frame1} alt='Fashion' className='w-full' />
          <figcaption className='text-white text-[32px] md:text-[20px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 lg:text-[30px] xl:text-[40px] font-normal leading-normal capitalize'>
            Fashion{' '}
          </figcaption>
        </figure>
        <figure className='relative'>
          <img src={Frame2} alt='Tech & Gadgets' />
          <figcaption className='text-white text-[32px] md:text-[20px] lg:text-[30px] xl:text-[40px] absolute top-1/2 left-[40%] transform -translate-x-[40%] md:-translate-x-1/2 -translate-y-1/2 font-normal leading-normal capitalize'>
            Tech & Gadgets
          </figcaption>
        </figure>
        <figure className='relative'>
          <img src={Frame3} alt='Home & Decor' />
          <figcaption className='text-white text-[32px] md:text-[20px] lg:text-[30px] xl:text-[40px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-normal leading-normal capitalize'>
            Home & Decor{' '}
          </figcaption>
        </figure>
        <figure className='relative'>
          <img src={Frame4} alt='Health & beauty' />
          <figcaption className='text-white text-[32px] md:text-[20px] lg:text-[30px] xl:text-[40px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-normal leading-normal capitalize'>
            Health & beauty
          </figcaption>
        </figure>
      </article>
      {/* mobile */}
      <article className='w-full flex md:hidden flex-col pt-[28px] justify-center'>
        <figure className='relative'>
          <img src={f1} alt='Fashion' className='w-full' />
          <figcaption className='text-white small:text-[28px] iphone:text-[32px] md:text-[20px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 lg:text-[30px] xl:text-[40px] font-normal leading-normal capitalize'>
            Fashion{' '}
          </figcaption>
        </figure>
        <figure className='relative'>
          <img src={f2} alt='Tech & Gadgets' />
          <figcaption className='text-white small:text-[28px] iphone:text-[32px] md:text-[20px] lg:text-[30px] xl:text-[40px] absolute top-1/2 iphone:left-[38%] small:left-[35%] transform -translate-x-[28%] iphone:-translate-x-[32%] md:-translate-x-1/2 -translate-y-1/2 font-normal leading-normal capitalize'>
            Tech & Gadgets
          </figcaption>
        </figure>
        <figure className='relative'>
          <img src={f3} alt='Home & Decor' />
          <figcaption className='text-white text-[28px] iphone:text-[32px] md:text-[20px] lg:text-[30px] xl:text-[40px] absolute top-1/2 left-[35%] transform -translate-x-[25%] -translate-y-1/2 font-normal leading-normal capitalize'>
            Home & Decor{' '}
          </figcaption>
        </figure>
        <figure className='relative'>
          <img src={f4} alt='Health & beauty' />
          <figcaption className='text-white text-[28px] iphone:text-[32px] md:text-[20px] lg:text-[30px] xl:text-[40px] absolute top-1/2 left-[35%] transform -translate-x-[30%] -translate-y-1/2 font-normal leading-normal capitalize'>
            Health & beauty
          </figcaption>
        </figure>
      </article>
    </main>
  );
};

export default Categories;
