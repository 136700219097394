import React from 'react';

const Missions = () => {
  return (
    <main className='bg-[#010917] md:h-[1402px] h-[1800px] lg:h-[760px] mac:h-[800px]'>
      <section className='xl:max-w-[75rem] lg:max-w-[60rem] md:pt-[20px] lg:pt-[60px] mac:pt-[50px] mac:max-w-[83rem] mx-auto flex flex-col items-center lg:items-start gap-14 lg:gap-0 lg:flex-row lg:justify-between'>
        <article className='mac:w-[630px] md:w-[520px] lg:w-[450px] xl:w-[550px] h-[775px] md:h-[630px] px-6 iphone:px-9 md:px-[31px] py-[33px] bg-[#0E1523]'>
          <header className='flex gap-[35px]  items-center'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              //   width='70'
              //   height='70'
              viewBox='0 0 70 70'
              fill='none'
              className='lg:h-[70px] lg:w-[70px] w-[40px] h-[40px]'
            >
              <circle cx='35' cy='35' r='35' fill='#FCFAE4' />
              <path
                d='M46.5043 23.0934L50.0774 23.8281C50.2693 23.8676 50.427 24.0123 50.4824 24.2075C50.5104 24.3023 50.5129 24.4032 50.4897 24.4993C50.4664 24.5955 50.4182 24.6834 50.3503 24.7536L47.4232 27.7669C47.0725 28.1256 46.5982 28.3274 46.1036 28.3283H43.6753L37.5781 34.6026C37.6945 35.0544 37.7158 35.5266 37.6409 35.9875C37.5659 36.4485 37.3963 36.8878 37.1434 37.2761C36.8904 37.6643 36.56 37.9927 36.174 38.2393C35.788 38.4859 35.3554 38.6451 34.9049 38.7062C34.4545 38.7673 33.9965 38.7289 33.5616 38.5937C33.1267 38.4584 32.7248 38.2294 32.3826 37.9219C32.0405 37.6143 31.766 37.2353 31.5774 36.81C31.3889 36.3848 31.2906 35.9231 31.2891 35.4557C31.289 34.9509 31.4018 34.4529 31.6188 34.0001C31.8358 33.5474 32.1512 33.1521 32.5406 32.8449C32.9299 32.5377 33.3828 32.3267 33.8641 32.2284C34.3454 32.1301 34.8423 32.1471 35.3162 32.278L41.4155 26.0014V23.5079C41.4155 22.9991 41.6117 22.5101 41.9613 22.1504L44.8905 19.1372C44.9588 19.0673 45.0442 19.0177 45.1377 18.9938C45.2311 18.9698 45.3292 18.9724 45.4213 19.0012C45.6111 19.0582 45.7518 19.2205 45.7902 19.4179L46.5043 23.0934Z'
                fill='#062A5E'
              />
              <path
                d='M21.6988 35.4555C21.701 37.337 22.0954 39.1962 22.8555 40.9076C23.6155 42.6191 24.7236 44.1429 26.1048 45.3763C27.486 46.6098 29.1084 47.5242 30.8623 48.0578C32.6163 48.5914 34.4611 48.7319 36.2724 48.4697C38.0836 48.2075 39.8191 47.5488 41.3618 46.538C42.9046 45.5272 44.2186 44.1878 45.2153 42.6102C46.2121 41.0325 46.8683 39.2533 47.1397 37.3926C47.4112 35.5319 47.2916 33.6329 46.7889 31.8238C46.7184 31.6124 46.6904 31.3885 46.7066 31.1656C46.7228 30.9428 46.783 30.7257 46.8834 30.5275C46.9838 30.3293 47.1224 30.1542 47.2907 30.0129C47.459 29.8716 47.6535 29.767 47.8623 29.7055C48.0712 29.6439 48.29 29.6268 48.5055 29.655C48.721 29.6833 48.9287 29.7563 49.1159 29.8698C49.3031 29.9832 49.4658 30.1346 49.5943 30.3149C49.7228 30.4951 49.8142 30.7003 49.8631 30.9181C50.8364 34.4215 50.6515 38.1582 49.3375 41.542C48.0235 44.9257 45.6547 47.7649 42.6028 49.6143C39.5509 51.4637 35.9885 52.2185 32.4745 51.7604C28.9605 51.3023 25.6938 49.6572 23.1868 47.0831C20.6826 44.5048 19.0816 41.144 18.6352 37.5284C18.1889 33.9128 18.9224 30.247 20.7207 27.1067C22.519 23.9664 25.2801 21.5293 28.5707 20.1781C31.8612 18.8269 35.4948 18.6381 38.901 19.6414C39.3065 19.7633 39.6487 20.0455 39.8527 20.4261C40.0566 20.8068 40.1058 21.2551 39.9895 21.673C39.8731 22.0908 39.6007 22.4443 39.2318 22.6562C38.8628 22.8681 38.4273 22.9211 38.0205 22.8037C36.1158 22.2411 34.1101 22.1414 32.1615 22.5125C30.213 22.8836 28.3749 23.7153 26.7923 24.9421C25.2096 26.1689 23.9257 27.7572 23.0418 29.5817C22.1578 31.4062 21.6981 33.417 21.6988 35.4555Z'
                fill='#062A5E'
              />
              <path
                d='M28.0973 35.4551C28.0974 36.6538 28.4157 37.8297 29.0178 38.856C29.6198 39.8823 30.4829 40.72 31.5138 41.2788C32.5447 41.8376 33.7044 42.0963 34.8677 42.0269C36.031 41.9575 37.1538 41.5627 38.115 40.8851C39.0761 40.2058 39.8383 39.2691 40.3193 38.1764C40.8002 37.0837 40.9816 35.8765 40.8438 34.6853C40.8066 34.3963 40.8449 34.1023 40.9548 33.8335C41.0647 33.5648 41.2423 33.331 41.4692 33.1563C41.6962 32.9816 41.9643 32.8723 42.2459 32.8396C42.5276 32.8069 42.8126 32.8521 43.0717 32.9704C43.3307 33.0871 43.5549 33.2725 43.7216 33.5075C43.8883 33.7425 43.9914 34.0188 44.0204 34.3081C44.252 36.3435 43.862 38.4026 42.9043 40.1996C41.9467 41.9966 40.4691 43.4423 38.6764 44.3362C36.8838 45.23 34.8652 45.5276 32.9006 45.1877C30.9361 44.8478 29.1232 43.8872 27.7135 42.4392C26.3038 40.9913 25.3672 39.1278 25.0338 37.1074C24.7003 35.0871 24.9866 33.01 25.8528 31.1646C26.719 29.3192 28.1222 27.7969 29.8676 26.8091C31.613 25.8213 33.614 25.4169 35.593 25.6521C35.8049 25.6724 36.0107 25.7359 36.1985 25.839C36.3862 25.9421 36.552 26.0828 36.6862 26.2527C36.8204 26.4226 36.9203 26.6183 36.98 26.8284C37.0397 27.0386 37.058 27.2588 37.0339 27.4763C37.0098 27.6939 36.9437 27.9042 36.8395 28.0951C36.7353 28.286 36.5951 28.4536 36.4272 28.588C36.2592 28.7224 36.0669 28.821 35.8614 28.878C35.6559 28.935 35.4415 28.9492 35.2306 28.9198C34.3345 28.8128 33.4265 28.902 32.5662 29.1815C31.706 29.4611 30.913 29.9247 30.2393 30.5419C29.5656 31.1592 29.0265 31.916 28.6573 32.7628C28.2881 33.6095 28.0973 34.5271 28.0973 35.4551Z'
                fill='#062A5E'
              />
            </svg>

            <h2 className='text-[#FCFCFC] text-[24px] lg:text-[28px] xl:text-[32px] font-normal leading-[60px]'>
              Our Mission{' '}
            </h2>
          </header>

          <p className='md:pt-[29px] pt-[24px] small:text-[15px] iphone:text-[18px] text-[#FCFCFC] lg:text-[13.7px] font xl:text-[17px] mac:text-[19px] leading-[32px] font-medium'>
            Our e-commerce venture, JAYKAY EDWARDS LTD, is dedicated{' '}
            <br className='hidden mac:block' />
            to revolutionizing the way consumers experience online{' '}
            <br className='hidden mac:block' />
            shopping. Our mission is to provide a seamless and personalized
            <br className='hidden mac:block' /> shopping experience for
            customers worldwide, offering a<br className='hidden mac:block' />{' '}
            diverse range of high-quality products while prioritizing
            <br className='hidden mac:block' />
            convenience and customer satisfaction.
            <br />
            <br />
            At JayKay Edwards, we believe shopping should be an{' '}
            <br className='hidden mac:block' />
            adventure. That’s why we bring you the latest and greatest{' '}
            <br className='hidden mac:block' />
            products from around the globe, all in one place. Whether{' '}
            <br className='hidden mac:block' />
            you’re looking for the hottest fashion trends, cutting-edge
            <br className='hidden mac:block' />
            electronics, unique home décor, or must-have gadgets, we’ve
            <br className='hidden mac:block' /> got you covered.
          </p>
        </article>
        <article className='mac:w-[630px] md:w-[520px] lg:w-[450px] xl:w-[550px] h-[860px] md:h-[720px] lg:h-[630px] px-6 iphone:px-9 md:px-[31px] py-[33px] bg-[#0E1523]'>
          <header className='flex gap-[35px] items-center'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              //   width='70'
              //   height='70'
              viewBox='0 0 70 70'
              fill='none'
              className='lg:h-[70px] lg:w-[70px] w-[40px] h-[40px]'
            >
              <circle cx='35' cy='35' r='35' fill='#FCFAE4' />
              <path
                d='M35 44.07L44.888 50.2092L42.264 38.6386L51 30.8536L39.496 29.8497L35 18.9375L30.504 29.8497L19 30.8536L27.736 38.6386L25.112 50.2092L35 44.07Z'
                fill='#062A5E'
              />
            </svg>

            <h2 className='text-[#FCFCFC] text-[24px] lg:text-[28px] mac:text-[32px] font-normal leading-[60px]'>
              Our Vision
            </h2>
          </header>

          <p className='md:pt-[29px] pt-[24px] small:text-[15px] iphone:text-lg text-[#FCFCFC] font lg:text-[13.7px] xl:text-[17px] mac:text-[19px] leading-[32px] font-medium'>
            Our vision is to become a leading e-commerce destination{' '}
            <br className='hidden mac:block' /> recognized for innovation,
            reliability, and exceptional service.{' '}
            <br className='hidden mac:block' /> We aim to continually enhance
            our platform, leveraging cutting-
            <br className='hidden mac:block' />
            edge technology and strategic partnerships to anticipate and
            <br className='hidden mac:block' /> exceed the evolving needs of our
            customers.
            <br />
            <br />
            Key highlights of our business plan include a comprehensive{' '}
            <br className='hidden mac:block' />
            market analysis revealing significant opportunities for growth, a
            <br className='hidden mac:block' />
            robust marketing and sales strategy focused on digital channels{' '}
            <br className='hidden mac:block' />
            and customer engagement, and a commitment to operational{' '}
            <br className='hidden mac:block' />
            excellence through efficient logistics and customer service. With{' '}
            <br className='hidden mac:block' />a strong financial plan in place
            and a dedicated team driving our
            <br className='hidden mac:block' /> vision forward, JAYKAY EDWARDS
            LTD is poised for success in
            <br className='hidden mac:block' />
            the competitive e-commerce landscape.Our e-commerce
            <br className='hidden mac:block' /> venture, JAYKAY EDWARDS LTD,
          </p>
        </article>
      </section>
    </main>
  );
};

export default Missions;
