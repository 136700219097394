import React from 'react';
import wear1 from '../../../images/wear1.png';
import wear2 from '../../../images/wear2.png';
import wear3 from '../../../images/wear3.png';
import wear4 from '../../../images/wear4.png';

const Adventure = () => {
  return (
    <main className='bg-[#010917] md:h-[580px] h-[1600px] iphone:h-[1570px] lg:h-[730px] xl:h-[880px] mac:h-[1000px] xl:pt-[30px] mac:pt-[50px]'>
      <h2 className='text-white text-[22px] px-5 md:px-0 md:text-[30px] leading-normal lg:text-[32px] xl:text-[40px] text-center md:leading-[60px] font-normal capitalize'>
        We believe shopping should be an{' '}
        <span className='text-[#FBE810]'>Adventure</span>{' '}
      </h2>
      <section className='xl:max-w-[75rem] md:max-w-[45rem] md:pt-[50px] lg:max-w-[60rem] px-5 iphone:px-0 iphone:max-w-[21rem] mac:max-w-[83rem] pt-[40px] mx-auto lg:pt-[65px] gap-[45px] md:gap-0 mac:pt-[83px] flex flex-col items-center md:flex-row md:justify-between'>
        <article className='space-y-[33px] '>
          <figure className='flex md:gap-[20px] gap-[20px] lg:gap-[33px]'>
            <img
              src={wear1}
              alt=''
              className='md:h-[140px] mt-4 md:mt-0 h-[110px] iphone:h-[150px] lg:mt-0 lg:h-[200px] xl:h-[260px]'
            />
            <img
              src={wear2}
              alt=''
              className='md:h-[190px] h-[150px] iphone:h-[200px] xl:h-auto lg:h-[300px] xl:w-auto'
            />
          </figure>
          <figure className='flex gap-[20px] md:gap-[20px] lg:gap-[33px]'>
            <img
              src={wear4}
              alt=''
              className='lg:-mt-[6.7rem] -mt-10 h-[150px] iphone:h-[200px] xl:-mt-[6.5rem] md:h-[190px] md:-mt-[50px] lg:w-[200px] lg:h-[320px] xl:w-[259px] xl:h-[360px]'
            />
            <img
              src={wear3}
              alt=''
              className='md:h-[140px] h-[110px] iphone:h-[150px] -mt-4 iphone:-mt-1 md:-mt-0 lg:h-[210px] xl:h-[256px]'
            />
          </figure>
        </article>
        <article>
          <p className='text-[#FCFCFC] text-[15px] iphone:text-lg leading-[32px] md:leading-normal font md:w-[25rem] md:text-[12.3px] lg:text-[13px] lg:w-[30rem] xl:text-[17px] mac:text-xl xl:w-[36rem] mac:w-[42rem] font-medium lg:leading-[30px] xl:leading-[32px]'>
            JAYKAY EDWARDS LTD is an innovative e-commerce platform that caters
            to the needs of modern consumers seeking convenience, quality, and a
            personalized shopping experience. Our platform offers a diverse
            selection of products ranging from fashion and electronics to home
            goods and beyond, curated to meet the varied preferences of our
            target market. <br />
            <br />
            Our target market comprises tech-savvy individuals, predominantly
            urban dwellers with disposable income and a preference for online
            shopping. They value convenience, product quality, and seamless
            transactions, seeking a platform that delivers on these
            expectations. Our unique selling proposition lies in our commitment
            to personalized recommendations and tailored shopping experiences
            through advanced AI algorithms. By analyzing user preferences and
            behavior, we offer curated product suggestions, enhancing the
            shopping journey and increasing customer satisfaction.
            <br /> <br />
            In terms of legal structure, JAYKAY EDWARDS LTD operates as a
            Limited Liability Company (LLC), providing us with the necessary
            legal protection and flexibility to scale our operations while
            mitigating risks associated with business ownership.
          </p>
        </article>
      </section>
    </main>
  );
};

export default Adventure;
