import React from 'react';
import team1 from '../../../images/team1.png';
import team2 from '../../../images/team2.png';
import team3 from '../../../images/team3.png';
import ceo from '../../../images/ceo.png';

const Teams = () => {
  const teams = [
    {
      id: 1,
      name: 'Ejiro Edwards',
      title: 'Chief Executive Officer (CEO) ',
      img: team1,
    },
    {
      id: 2,
      name: 'Ejiro Edwards',
      title: 'Chief Executive Officer (CEO) ',
      img: team2,
    },
    {
      id: 3,
      name: 'Ejiro Edwards',
      title: 'Chief Executive Officer (CEO) ',
      img: team3,
    },
  ];
  return (
    <main className='bg-[#010917] h-[410px] iphone:h-[460px] md:h-[760px] lg:h-[780px] xl:h-[900px] mac:h-[1100px]'>
      <section className='mac:max-w-[83rem] px-6 iphone:px-0 iphone:max-w-[21rem] md:max-w-[45rem] md:pt-[90px] lg:pt-[40px] xl:pt-[60px] lg:max-w-[60rem] xl:max-w-[75rem] mx-auto'>
        <h2 className='text-[#FFF] text-[24px] md:text-[40px] text-center font-normal leading-[60px] capitalize'>
          Meet Our CEO{' '}
        </h2>

        <article className='flex flex-col gap-[52px] md:gap-0 items-center md:justify-center pt-[45px] md:pt-[50px] pb-[70px] xl:pt-[74px]'>
          {/* {teams.map((team) => (
            <figure>
              <img
                src={team.img}
                alt=''
                className='xl:w-[350px] md:w-[200px] md:h-[200px] lg:h-[300px] lg:w-[300px] xl:h-[350px] mac:h-auto mac:w-auto'
              />
              <figcaption>
                <h2 className='text-[#FCFCFC] pt-[30px] font-normal font text-[27px] leading-normal'>
                  {team.name}
                </h2>

                <p className='text-[#FCFCFC] pt-[10.15px] font font-normal leading-notmal text-[13.5px]'>
                  {team.title}
                </p>
              </figcaption>
            </figure>
          ))} */}
          <figure className='flex flex-col justify-center items-center'>
            <img
              src={ceo}
              alt='ceo-picture'
              className='object-contain rounded-lg  md:h-[390px] lg:h-[480px] xl:h-[520px] mac:h-[680px]'
            />
            <figcaption>
              <h2 className='text-[#FCFCFC] font pt-[30px] leading-normal font-normal md:text-[25px] xl:text-[32px]'>
                Ejiro Edward Chief Executive Officer (CEO){' '}
              </h2>
            </figcaption>
          </figure>
        </article>
      </section>
    </main>
  );
};

export default Teams;
