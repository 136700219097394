import React from 'react';
import about from '../images/about.png';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <main className='bg-[#010917] h-[59rem] md:h-[38rem] lg:h-[45rem] pt-[30px] md:pt-[4rem] lg:pt-[6rem] xl:pt-[3rem] mac:pt-[5rem]'>
      <section className='mac:max-w-[83rem] px-7 md:px-0 gap-5 md:gap-0 md:max-w-[45rem] lg:max-w-[60rem] xl:max-w-[75rem] mx-auto flex flex-col md:flex-row items-center md:justify-between'>
        <article>
          <h1 className='text-[#FBE810] font text-center md:text-left text-base font-bold md:text-[24px] tracking-[12px] uppercase leading-normal md:leading-[60px]'>
            About Us{' '}
          </h1>
          <h2 className='text-white pt-[30px] md:leading-normal text-center md:text-left text-[22px] md:text-[22px] lg:text-[30px] xl:text-[40px] font-normal lg:leading-[60px] capitalize'>
            We believe shopping should be
            <br /> an Adventure{' '}
          </h2>

          <p className='md:pt-[14px] font pt-[24px] text-lg text-[#DBDBDB] flex flex-wrap md:text-[13px] leading-[32px] xl:text-[18px] mac:text-[20px] font-medium'>
            JAYKAY EDWARDS LTD is an innovative e-commerce{' '}
            <br className='hidden md:block lg:hidden' />
            platform that <br className='hidden lg:block' />
            caters to the needs of modern consumers{' '}
            <br className=' hidden md:block lg:hidden' />
            seeking convenience,
            <br className='hidden lg:block' /> quality, and a personalized
            shopping <br className=' hidden md:block lg:hidden' />
            experience. Our platform
            <br className='hidden lg:block' />
            offers a diverse selection of products
            <br className=' hidden md:block lg:hidden' /> ranging from fashion
            and
            <br className='hidden lg:block' />
            electronics to home goods and
            <br className=' hidden md:block lg:hidden' /> beyond,curated to meet
            the
            <br className='hidden lg:block' /> varied preferences of{' '}
            <br className=' hidden md:block lg:hidden' />
            our target market
          </p>
          <button className='bg-[#FBE810] font hidden md:flex  mt-[30px] rounded-lg font-bold px-7 py-3 text-[#010917]'>
            <Link to='/about'> Read more</Link>
          </button>
        </article>
        <figure>
          <img
            src={about}
            alt='about us'
            className='xl:w-auto md:w-[300px] md:h-auto lg:w-[450px] lg:h-[400px] xl:h-[500px]'
          />
        </figure>
        <button className='bg-[#FBE810] font block w-full md:hidden  mt-[30px] rounded-lg font-bold px-7 py-3 text-[#010917]'>
          <Link to='/about'>Read more</Link>
        </button>
      </section>
    </main>
  );
};

export default About;
