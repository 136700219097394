import React from 'react';
import business from '../images/business.png';
import { Link } from 'react-router-dom';

const Business = () => {
  return (
    <main className='bg-[#010917] h-[930px] md:h-[480px] lg:h-[550px] xl:h-[620px]'>
      <section className='mac:max-w-[83rem] px-7 md:px-0 md:max-w-[45rem] lg:max-w-[60rem] xl:max-w-[75rem] mx-auto pt-[60px] lg:pt-[80px] xl:pt-[130px] gap-[60px] md:gap-0 flex flex-col items-center md:flex-row md:justify-between'>
        <article>
          <h2 className='lg:text-[30px] text-[22px] text-center md:text-left md:text-[25px] leading-normal xl:text-[40px] font-normal lg:leading-[60px] text-white'>
            Start Your <span className='text-[#FBE810]'>Online Business</span>
            <br /> with Ease
          </h2>

          <p className='pt-[31px] font md:pt-[27px] font-medium leading-[32px] text-lg w-full md:text-[12px] md:w-[22rem] lg:w-[28rem] xl:w-[35rem] lg:text-[16px] xl:text-[20px] text-[#DBD]'>
            Are you ready to launch your own online store but don't know where
            to start? At JayKay Edwards, we make it simple and stress-free for
            you to kickstart your e-commerce journey. Our platform is designed
            to empower aspiring entrepreneurs like you, providing all the tools
            and support you need to succeed.
          </p>
          <button className='bg-[#FBE810] font hidden md:flex  mt-[30px] rounded-lg font-bold px-7 py-3 text-[#010917]'>
            <Link to='/contact'>Get in touch</Link>
          </button>
        </article>
        <figure>
          <img
            src={business}
            alt='business-image'
            className=' xl:w-auto md:w-[290px] lg:w-[400px] lg:h-[350px] xl:h-[420px]'
          />
        </figure>
        <button className='bg-[#FBE810] block w-full md:hidden  mt-[20px] rounded-lg font-bold px-7 py-3 text-[#010917]'>
          <Link to='/contact'> Get in touch</Link>
        </button>
      </section>
    </main>
  );
};

export default Business;
