import React from 'react';
import About2 from '../../../images/About2.png';
import About3 from '../../../images/About3.png';
import About4 from '../../../images/About4.png';

const Header = () => {
  return (
    <header className=''>
      <figure className='relative '>
        <img
          src={About3}
          alt=''
          className='object-cover hidden md:block w-full h-[255px] md:h-auto'
        />
        <img src={About4} alt='' className='object-cover md:hidden w-full' />
        <figcaption className='absolute text-white text-[30px] iphone:text-[40px] md:text-[60px] font-normal leading-[60px] top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2'>
          About <span className='text-[#FBE810]'>Us</span>
        </figcaption>
      </figure>
    </header>
  );
};

export default Header;
