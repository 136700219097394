import React from 'react';
import experience from '../images/experience.png';
import experience2 from '../images/experience2.png';
import { Link } from 'react-router-dom';

const Experience = () => {
  return (
    <main className=''>
      <figure className='relative'>
        <img
          src={experience}
          alt='experience'
          className='w-full hidden md:block md:h-[20rem] lg:h-auto object-cover'
        />
        <img
          src={experience2}
          alt='experience'
          className='w-full block md:hidden small:h-[30rem] iphone:h-full'
        />
        <figcaption className='absolute left-[50%] small:w-[20rem] iphone:w-[21rem] md:w-full -translate-x-[50%] top-1/2 transform -translate-y-1/2 flex flex-col items-center'>
          <h1 className='text-white small:text-[28px] iphone:text-[33px] text-center md: md:text-[30px] lg:text-[35px] xl:text-[52px] md:leading-normal lg:leading-[60px] capitalize font-normal'>
            Shop now and experience the magic
          </h1>
          <p className='text-white small:px-2 iphone:px-0 small:text-[15px] iphone:text-[17px] w-full md:text-[14.5px] lg:text-[20px] font xl:text-[26px] pt-[40px] md:pt-[17px] md:w-[500px] lg:w-[650px] xl:w-[894px] text-center leading-[36px] font-medium'>
            Join thousands of satisfied customers who have made JayKay Edwards
            their go-to shopping destination. At JayKay Edwards, we turn
            everyday shopping into an extraordinary experience.
          </p>
          <button className='bg-[#FBE810] small:w-[18rem] iphone:w-full mt-[60px] md:mt-[30px] md:w-auto lg:mt-[49px] text-sm lg:text-base rounded-lg font-bold px-7 py-3 text-[#010917]'>
            <Link to='/contact'> Get in touch</Link>
          </button>
        </figcaption>
      </figure>
    </main>
  );
};

export default Experience;
