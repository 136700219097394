import React from 'react';
import measuring from '../images/measuring.svg';
import trigger from '../images/trigger.svg';
import cervical from '../images/cervical.svg';
import jelly from '../images/jelly.svg';
import badminton from '../images/badminton.svg';
import led from '../images/led.svg';
import { useNavigate } from 'react-router-dom';

const Products = () => {
  const navigate = useNavigate();
  const products = [
    {
      id: 1,
      img: measuring,
      text: ['Digital Measuring', 'Cup'],
      price: '$29.99',
    },
    {
      id: 2,
      img: trigger,
      text: ['Cervical Trigger', 'Point Massager'],
      price: '$19.99',
    },
    {
      id: 3,
      img: cervical,
      text: ['EMS Cervical', 'Massager', 'Stretcher'],
      price: '$49.99',
    },
    {
      id: 4,
      img: jelly,
      text: ['Jellyfish Night', 'Light'],
      price: '$39.99',
    },
    {
      id: 5,
      img: badminton,
      text: ['Badminton Self', 'Training Tool'],
      price: '$19.99',
    },
    {
      id: 6,
      img: led,
      text: ['Led Therapy', 'Light'],
      price: '$50.99',
    },
  ];

  return (
    <section className='bg-[#010917] pt-[60px] md:pt-[3.16rem] h-[920px] md:h-[1700px] lg:pt-[4.56rem] xl:pt-[9.86rem] lg:h-[1130px] xl:h-[1380px]'>
      <h3 className='text-[1.625rem] md:text-[2.1rem] xl:text-[2.5rem] text-white text-center font-normal leading-[3.75rem] capitalize'>
        Some of our <span className='text-[#FBE810]'>Products</span>{' '}
      </h3>

      <article className='grid grid-cols-2 lg:grid-cols-3 pt-[2.32rem] md:pt-[4.35rem] gap-x-[1.5rem] md:gap-x-[1.75rem] gap-y-[3.25rem] md:gap-y-[4.25rem] px-2 small:max-w-[22rem] mac:max-w-[83rem] md:max-w-[45rem] lg:max-w-[60rem] xl:max-w-[75rem] mx-auto'>
        {products.map((items) => (
          <div key={items.id} className=''>
            <img src={items.img} alt='products' />
            <div>
              <span className='flex justify-between pt-[1.25rem] md:pt-[1.5rem] lg:pt-[1.81rem]'>
                <p className='text-[#DBD] font text-[12px] md:text-[1.25rem] lg:text-[1.32rem] xl:text-[1.75rem] leading-[1.23206rem] md:leading-[2.5rem] font-medium'>
                  {items.text.map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      {index < items.text.length - 1 &&
                        // Only break on small screens for 'EMS Cervical Massager Stretcher'
                        (items.text.join(' ') ===
                        'EMS Cervical Massager Stretcher' ? (
                          <>
                            <br className='block md:hidden' />
                            <span className='hidden md:inline'> </span>
                          </>
                        ) : (
                          <br />
                        ))}
                    </React.Fragment>
                  ))}
                </p>
                <p className='text-[#DBD] font text-xs md:text-[1.125rem] lg:text-[1.22rem] xl:text-[1.5rem] leading-[1.23206rem] md:leading-[2.5rem] font-medium'>
                  {items.price}
                </p>
              </span>
              <div
                className={`flex justify-end items-end ${
                  items.id === 3 ? '-mt-9' : ''
                } -mt-4 md:-mt-8 lg:-mt-7`}
              >
                <button
                  onClick={() => navigate(`/product/${items.id}`)}
                  className='px-2 md:px-3 py-1 text-[0.625rem] md:text-base bg-[#FBE810] font text-[#010917] rounded-[0.5rem]'
                >
                  Read more
                </button>
              </div>
            </div>
          </div>
        ))}
      </article>
    </section>
  );
};

export default Products;
