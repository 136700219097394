import React, { useRef } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import emailjs from '@emailjs/browser';

const Input = () => {
  const form = useRef();
  const nameRef = useRef(null);
  const subjectRef = useRef(null);
  const emailRef = useRef(null);
  const messageRef = useRef(null);

  const sendEmail = (e) => {
    e.preventDefault();
    if (
      !nameRef.current.value ||
      !subjectRef.current.value ||
      !emailRef.current.value.includes('@') ||
      !messageRef.current.value
    ) {
      toast.error('Please fill out all fields correctly.');
      return;
    }

    console.log('Form data:', {
      name: nameRef.current.value,
      subject: subjectRef.current.value,
      email: emailRef.current.value,
      message: messageRef.current.value,
    });

    emailjs
      .sendForm(
        'service_feggqul',
        'template_bdc10w7',
        form.current,
        'QslX8P8_GrnOdU44a'
      )
      .then((result) => {
        toast.success('Email Successfully Sent');
        console.log('SUCCESS!', result);

        // Clear form fields after successful submission
        nameRef.current.value = '';
        subjectRef.current.value = '';
        emailRef.current.value = '';
        messageRef.current.value = '';
      })
      .catch((error) => {
        // Handle network errors separately
        toast.error('Failed to send message. Please try again later.');
        console.error('FAILED...', error);
      });
  };
  return (
    <article className='bg-[#010917] h-[59rem] md:h-[61rem] lg:h-[63rem]'>
      <Toaster position='top-center' reverseOrder={true} />
      <section className='iphone:max-w-[20rem] px-7 iphone:px-0 md:max-w-[38rem] lg:max-w-[42rem] xl:max-w-[45rem] mac:max-w-[50rem] mx-auto'>
        <h5 className='text-[#fcfcfc] text-left text-[22px] md:text-[32px] lg:text-[40px] leading-[60px] font-normal'>
          SEND US A <span className='text-[#FBE810]'>MESSAGE</span>
        </h5>

        <h2 className='font pt-[25px] md:pt-[45px] text-[#DBDBDB] text-[15px] iphone:text-lg md:text-xl trackin-[0.1px] leading-normal font-medium'>
          We’re always eager to hear from our customers. Use the form below to
          send us a message and we’ll get back to you as soon as possible. Your
          feedback and inquiries help us serve you better.
        </h2>
        <div className='pt-[3.5rem] lg:pt-[62px] flex flex-col items-center gap-12 md:flex-row md:justify-between'>
          <form
            ref={form}
            onSubmit={sendEmail}
            className='mac:space-y-[2.5rem] w-full space-y-[2.2rem] md:space-y-[2.5rem] lg:space-y-[2.7rem] '
          >
            <div className=''>
              <input
                type='text'
                ref={nameRef}
                id='name'
                name='name'
                placeholder='Name'
                className='border-2 border-[#A3A3A3] font text-white pl-5 focus:outline-blue-300  w-full h-[70px] bg-[#010917] md:h-[4rem] rounded-md'
              />
            </div>

            <div className=' w-full'>
              <input
                type='email'
                id='email'
                name='email'
                ref={emailRef}
                placeholder='Email'
                className='border-2 border-[#A3A3A3] font text-white focus:outline-blue-400  pl-5 w-full h-[70px] bg-[#010917] md:h-[4rem] rounded-md'
              />
            </div>
            <div className=' w-full'>
              <input
                type='text'
                ref={subjectRef}
                id='subject'
                name='subject'
                placeholder='Subject'
                className='border-2 border-[#A3A3A3] font text-white focus:outline-blue-400  pl-5 w-full h-[70px] bg-[#010917] md:h-[4rem] rounded-md'
              />
            </div>

            <div className='w-full'>
              <textarea
                id='message'
                name='message'
                ref={messageRef}
                placeholder='Message'
                className='border-2 pt-7 text-white font border-[#A3A3A3] bg-[#010917] font rounded-xl pl-5 focus:outline-blue-300  w-full h-[180px] md:h-[16rem]'
              />
            </div>
            <span className='mt-[34px]'>
              <button
                type='submit'
                className='md:px-7 font h-[45px] md:w-auto w-full mt-[34px] rounded-xl bg-[#FBE810] text-[#010917]'
              >
                Submit
              </button>
            </span>
          </form>
        </div>
      </section>
    </article>
  );
};

export default Input;
