import React from 'react';
import contacts from '../../../images/contacts.png';
import contact2 from '../../../images/Contact2.png';

const Hero = () => {
  return (
    <header className='pt-10'>
      <figure className='relative'>
        <img
          src={contacts}
          alt=''
          className='object-cover hidden md:block w-full h-[305px] md:h-auto'
        />
        <img
          src={contact2}
          alt=''
          className='object-contain md:hidden w-full  md:h-auto'
        />
        <figcaption className='absolute text-white text-[30px] iphone:text-[40px] md:text-[45px] lg:text-[60px] font-normal leading-[60px] top-1/2 left-[40%] md:left-1/2 transform -translate-y-1/2 -translate-x-[35%] md:-translate-x-1/2'>
          Contact <span className='text-[#FBE810]'>Us</span>
        </figcaption>
      </figure>
    </header>
  );
};

export default Hero;
