import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Squash as Hamburger } from 'hamburger-react';
import { useLocation } from 'react-router-dom';

const Nav = () => {
  const location = useLocation();
  const [nav, setNav] = useState(false);
  return (
    <section className='bg-[#0E1523] z-[100] w-full fixed py-8 md:py-10'>
      <nav className='mac:max-w-[83rem] absolute top-2 md:top-[18px] left-0 right-0 z-50 md:max-w-[45rem] lg:max-w-[60rem] px-3 md:px-0 xl:max-w-[75rem] mx-auto flex items-center justify-between'>
        <Link to='/'>
          <header className='flex items-center gap-1 md:gap-3'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='29'
              height='27'
              viewBox='0 0 29 27'
              fill='none'
              className=' h-[20px] md:h-auto md:w-auto'
            >
              <g clip-path='url(#clip0_3423_1376)'>
                <path
                  d='M28.3762 0C28.3313 0.0653246 27.4657 1.21055 27.0778 1.72702C25.5039 3.8174 21.1517 9.04133 14.4355 12.8649C13.5679 13.3609 12.6575 13.8325 11.7102 14.2693C11.4163 14.4061 11.1162 14.5388 10.8141 14.6694L9.64844 11.9503C10.3568 11.6461 11.0448 11.3195 11.7102 10.9766C18.5591 7.44292 23.1073 2.04343 24.669 0H28.3762Z'
                  fill='#00AAFA'
                />
                <path
                  d='M28.3803 26.6219H24.7058C21.9091 22.6841 15.2623 14.79 10.2344 14.79V11.832C13.7252 11.832 17.7569 14.2266 22.2173 18.9504C25.408 22.3309 28.2864 26.4811 28.3803 26.6219Z'
                  fill='#00AAFA'
                />
                <path
                  d='M17.6288 0V3.25806C16.7449 4.05625 15.7589 4.8728 14.6708 5.66895V2.95798H8.75485L5.79688 0H17.6288Z'
                  fill='white'
                />
                <path
                  d='M16.6721 21.7406C15.2104 24.6312 12.2075 26.6195 8.75145 26.6195C4.36246 26.6195 0.708363 23.4166 0 19.2256H3.0233C3.68063 21.7732 5.99965 23.6615 8.75145 23.6615C11.3256 23.6615 13.5201 22.01 14.3306 19.7135C15.0798 20.2851 15.8657 20.9669 16.6721 21.7406Z'
                  fill='white'
                />
                <path
                  d='M11.708 5.95312V17.7483C11.708 19.3794 10.3811 20.7063 8.75 20.7063V5.95312H11.708Z'
                  fill='#00AAFA'
                />
              </g>
              <defs>
                <clipPath id='clip0_3423_1376'>
                  <rect
                    width='28.3754'
                    height='26.6218'
                    rx='4.05362'
                    fill='white'
                  />
                </clipPath>
              </defs>
            </svg>
            <h2 className='text-white md:mt-2 md:text-base lg:text-[19px] xl:text-[26.667px] leading-normal tracking-[0.56px] font-bold'>
              JAYKAY EDWARDS{' '}
              <span className='text-[#00AAFA] text-[14px]'>LTD.</span>
            </h2>
          </header>
        </Link>
        <span className='md:hidden'>
          <Hamburger
            direction='squash'
            size={25}
            color='white'
            className='burger-menu'
            toggled={nav}
            toggle={() => setNav(!nav)}
          />
        </span>{' '}
        <ul className='md:flex space-x-[3rem] hidden'>
          <li
            className={
              location.pathname === '/about'
                ? 'text-[#FBE810] mt-3 text-lg font-bold leading-normal font'
                : `text-[#FCFCFC] font hover:text-[#FBE810] text-lg font-bold leading-normal mt-3`
            }
          >
            <Link to='/about'>About</Link>
          </li>
          <li
            className={
              location.pathname === '/contact'
                ? 'text-[#FBE810] mt-3 text-lg font-bold leading-normal font'
                : `text-[#FCFCFC] font hover:text-[#FBE810] text-lg font-bold leading-normal mt-3`
            }
          >
            <Link to='/contact'>Contact</Link>
          </li>
          <button className='bg-[#FBE810] font rounded-lg font-bold px-7 py-3 text-[#010917]'>
            <Link to='/contact'>Get in touch</Link>
          </button>
        </ul>
      </nav>{' '}
      {nav && (
        <div className='fixed inset-0 z-20  bg-black bg-opacity-50'>
          <div className='w-full bg-[#0E1523] h-[250px] pt-5 px-6'>
            <ul className='flex-col flex pt-[47px] space-y-[2rem]'>
              <li
                className={
                  location.pathname === '/about'
                    ? 'text-[#FBE810] mt-3 text-lg font-bold leading-normal font'
                    : `text-[#FCFCFC] font hover:text-[#FBE810] text-lg font-bold leading-normal mt-3`
                }
              >
                <Link to='/about'>About</Link>
              </li>
              <li
                className={
                  location.pathname === '/contact'
                    ? 'text-[#FBE810] mt-3 text-lg font-bold leading-normal font'
                    : `text-[#FCFCFC] font hover:text-[#FBE810] text-lg font-bold leading-normal mt-3`
                }
              >
                <Link to='/contact'>Contact</Link>
              </li>
              {/* <button className='bg-[#FBE810] font rounded-lg font-bold px-7 py-3 text-[#010917]'>
              <Link to='/contact'>Get in touch</Link>
            </button> */}
            </ul>
          </div>
        </div>
      )}
    </section>
  );
};

export default Nav;
