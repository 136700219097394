import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';
import measuring from '../../../images/p1.png';
import trigger from '../../../images/p2.png';
import cervical from '../../../images/p3.png';
import jelly from '../../../images/p4.png';
import badminton from '../../../images/p5.png';
import led from '../../../images/p6.png';
import { Link } from 'react-router-dom';

const ProductDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);
  const productsData = [
    {
      id: 1,
      img: measuring,
      text: ['Digital Measuring', 'Cup'],
      description:
        'The Digital Measuring Cup is a multifunctional kitchen tool designed to make precise measurements effortless. Featuring a digital LCD display, it allows for easy readings and quick unit conversions between grams, milliliters, ounces, cups, and more. With a 1000g weight capacity and 1g graduation, this cup is perfect for measuring both solids and liquids. Its detachable design makes it compact and easy to store, while the built-in temperature sensor adds extra versatility. Made from durable PC and ABS materials, this measuring cup is available in red, green, and black, and includes convenient low voltage and overweight indicators. Powered by an included button battery, it’s the ideal companion for everyday kitchen tasks.',
      price: '$29.99',
    },
    {
      id: 2,
      img: trigger,
      text: ['Cervical Trigger', 'Point Massager'],
      description:
        'Experience unparalleled relief from neck and shoulder pain with our Cervical Traction Device, the ultimate neck stretcher massager designed for instant and lasting comfort. Whether you’re battling chronic neck pain, tension headaches, or poor posture from hours hunched over screens, this versatile tool is your go-to solution. Its precision-targeted trapezius trigger point release and GB20 acupuncture therapy work together to alleviate tension, restore proper cervical alignment, and relieve pinched nerves. Crafted from high-quality, resilient EVA material, this non-electric, portable device can be used anytime, anywhere, offering you professional-grade therapy in the comfort of your home. Say goodbye to stiffness, migraines, and stress—transform your daily routine with this essential tool for a pain-free, balanced life.',
      price: '$19.99',
    },
    {
      id: 3,
      img: cervical,
      text: ['EMS Cervical', 'Massager', 'Stretcher'],
      description:
        'Experience unparalleled relief from cervical spine problems and neck pain with our EMS Cervical Massager Stretcher. Designed for effective and comfortable neck traction, this innovative device features adjustable 6-speed massage strength and heating options, catering to your specific needs. Perfect for use at home, in the office, or during travel, its compact and lightweight design ensures you can carry it anywhere. Although it takes some time to get used to the device, consistent use will help realign your cervical spine and provide significant pain relief. Just remember, it’s not a regular pillow and should not be used for more than 30 minutes at a time. Our reliable customer service is here to assist with any questions or concerns you may have on your journey to better neck health.',
      price: '$49.99',
    },
    {
      id: 4,
      img: jelly,
      text: ['Jellyfish Night', 'Light'],
      description:
        'Transform any space into a tranquil haven with the Jellyfish Night Light. This captivating lamp features lifelike jellyfish that gently float and glide, illuminated by 17 vibrant, color-changing LED lights. Its fluid movements and soft glow create a mesmerizing display, perfect for adding a touch of calm to any bedroom, living room, or office. The soothing ambiance it provides helps to reduce stress and promote relaxation, making it an ideal piece for those seeking peace and serenity in their environment. An aesthetic and functional addition, it’s perfect for anyone looking to elevate their room’s atmosphere.',
      price: '$39.99',
    },
    {
      id: 5,
      img: badminton,
      text: ['Badminton Self', 'Training Tool'],
      description:
        'Enhance your badminton skills anytime with the Badminton Self Training Tool. Designed for single-player use, this telescopic trainer accurately mimics the flight and trajectory of real badminton games, offering a realistic solo practice experience. Lightweight and portable, it can be set up quickly at home or taken to various locations for versatile training. With multiple training modes, including footwork, smashes, and serves, this tool provides a comprehensive workout to refine your technique and elevate your game. Perfect for all skill levels, this badminton trainer is ideal for building agility, accuracy, and fitness from the comfort of your own space.',
      price: '$19.99',
    },
    {
      id: 6,
      img: led,
      text: ['Led Therapy', 'Light'],
      description:
        'Experience the Benefits of Dual Technology: With our red light therapy belt, immerse yourself in the synergy of red light therapy and infrared light therapy for body wellness, designed for use in the comfort of your home. Featuring a versatile design, the red light therapy device offers customizable sessions targeting areas like the neck and knee, making red light therapy at home both convenient and effective. Tailor Your Wellness Routine: Featuring a versatile design, the red light therapy device offers customizable sessions targeting areas like the neck and knee, making red light therapy at home both convenient and effective.',
      price: '$50.99',
    },
  ];
  const { id } = useParams();
  const product = productsData.find((item) => item.id === parseInt(id, 10));

  if (!product) {
    return <p>Product not found</p>;
  }

  return (
    <section className='my-[3rem] bg-white px-2 small:max-w-[22rem] mac:max-w-[75rem] md:max-w-[45rem] lg:max-w-[60rem] xl:max-w-[75rem] mx-auto text-black'>
      <Link to='/'>
        <div className='flex justify-end items-end'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 41 41'
            fill='none'
            className='w-[1.5rem] h-[1.5rem] md:w-[2.1875rem] md:h-[2.1875rem]'
          >
            <path
              d='M38 3L3 38M3 3L38 38'
              stroke='#060F1F'
              stroke-width='5.83333'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </div>
      </Link>
      <h6 className='text-[#010917] text-left md:text-center -mt-[2rem] md:-mt-[1rem] font-normal leading-[2.5rem] md:leading-[3.75rem] capitalize text-xl'>
        Product Description{' '}
      </h6>
      <h1 className='text-[1.5rem] md:text-[3rem] pt-[1.46rem] md:pt-0 text-[#010917] font leading-[2.5rem] md:leading-[4.25rem] font-medium text-center my-2'>
        {product.text.join(' ')}
      </h1>
      <div className=''>
        <p className='text-lg text-[#FBE810] bg-[#010917] mx-auto w-[8.5rem] h-[1.65019rem] md:w-[17.0625rem] md:h-[3.3125rem] flex justify-center items-center rounded-lg leading-[1.24544rem] md:leading-[2.5rem] text-center font font-medium md:text-[2.25rem]'>
          Price: {product.price}
        </p>
      </div>
      <div className='flex justify-center mt-[2.06rem]'>
        <img
          src={product.img}
          alt={product.text.join(' ')}
          className='md:w-[40.9375rem] lg:w-[46.9375rem] md:h-[29.4375rem] object-contain md:object-cover'
        />
      </div>

      <p className='mt-[3rem] md:w-[40.9375rem] lg:w-[46.9375rem] mx-auto font leading-[2rem] text-base small:text-[1.125rem] text-[#060F1F] font-medium'>
        {product.description}
      </p>
    </section>
  );
};

export default ProductDetails;
