import React, { useEffect } from 'react';
import Hero from './Hero';
import Footer from '../../Footer';
import Input from './Input';
import Nav from '../../Nav';

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);
  return (
    <div>
      <Nav />
      <Hero />
      <Input />
      <Footer />
    </div>
  );
};

export default Contact;
